import ProductDetailImages from '~/themes/base/components/product/ProductDetailImages'

export default {
  extends: ProductDetailImages,
  computed: {
    showRecommendedPrice() {
      return (
        Boolean(this.displayedVariant.prices.attributes?.recommended_price) &&
        this.displayedVariant.prices.originalWithTax > this.displayedVariant.prices.unitWithTax
      )
    },
  },
}

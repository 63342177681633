import { get, orderBy } from 'lodash'
import BadgeWidgets from '@theme/components/shop/BadgeWidgets'
import PageProductDetail from '~/themes/base/components/product/PageProductDetail'

export default {
  components: {
    BadgeWidgets,
  },
  mixins: [PageProductDetail],
  data() {
    return {
      brandEntityId: '5cb738b2e5cc4c0017ea7f53',
    }
  },
  methods: {
    getEnabledTabsAccordingToProjectSettings() {
      let tabs = []
      if (this.$themeSettings.components.PageProductDetail.showDescription) {
        tabs.push('description')
      }
      if (
        this.$themeSettings.components.PageProductDetail.accessoryProducts.enable &&
        this.accessoryProducts &&
        this.accessoryProducts.length > 0
      ) {
        tabs.push('accessories')
      }
      if (
        this.$store.state.globals.settings.functions.products_user_reviews &&
        this.$themeSettings.components.PageProductDetail.reviews.enable
      ) {
        tabs.push('reviews')
      }
      if (this.$themeSettings.components.PageProductDetail.showProductComments) {
        tabs.push('comments')
      }
      if (
        this.$themeSettings.components.PageProductDetail.alternativeProducts.enable &&
        this.alternativeProducts &&
        this.alternativeProducts.length > 0
      ) {
        tabs.push('alternatives')
      }
      return tabs
    },
  },
  computed: {
    alternativeColorVariants() {
      return orderBy([...this.alternativeProducts.filter(p => p.availability.isAvailable === true), this.product], 'id')
    },
    brand() {
      return this.parameterValue(this.brandEntityId)
    },
    canUserReview() {
      return get(this.productReviews, 'meta.custom.canUserReview', false)
    },
    categoryLinkBanner() {
      return this.$store.getters['globals/secondaryBanner']
    },
    categoryLinkBannerImage() {
      return get(this.categoryLinkBanner, 'image[0]') || {}
    },
    hasCategoryLinkBanner() {
      return this.$store.getters['globals/isSecondaryBannerEnabled']
    },
    showRecommendedPrice() {
      return (
        Boolean(this.displayedVariant.prices.attributes?.recommended_price) &&
        this.displayedVariant.prices.originalWithTax > this.displayedVariant.prices.unitWithTax
      )
    },
  },
}

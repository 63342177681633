import ProductShippersList from '~/themes/base/components/product/ProductShippersList'

export default {
  extends: ProductShippersList,
  computed: {
    shippers() {
      return this.$store.state?.cart?.shippers?.length > 0
        ? this.$store.state.cart.shippers
        : this.$store.state?.globals?.shippers || []
    },
  },
}
